.adminbox {
    width: 700px;
    margin: auto;
    color: #fff;
}

.cus-navlink {
    width: 50%;
    font-weight: 600 !important;
}

.modal-backdrop.fade {
    z-index: -1;
}

.adminbox-caption {
    color: #fff;

}

.cus-modal {
    color: black;
}

.background-active-bg {
    background-color: #fff !important;
    color: black !important;
    border-radius: 0 !important;
}

/* .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-radius: 0 !important;
} */

.cus-buttontex {
    color: "red";
}

@media only screen and (max-width: 600px) {
    .adminbox {
        width: 100%;
        margin: auto;
        color: #fff;
    }
}