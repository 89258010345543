@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300&family=Playfair+Display:wght@700&family=Poppins:wght@300;400&family=Rokkitt&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
h1 {
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
}

.countdown-wrapper {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: #36267c;
    border-radius: 10px;
}

.countdown-item {
    color: #ffffff;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
}

.countdown-item span {
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
/* font-family: 'Mulish', sans-serif;
font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif;
font-family: 'Rokkitt', serif; */
.navbar {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    /* line-height:26px; */
    /* text-transform: uppercase; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    background: #000000;

}

.logo-txt {
    color: #fff;
}

.connect-btn {
    padding: 5px 25px;
    color: white;
    background-color: #093adb;
    /* border: 1px solid gray; */
    border-radius: 20px;
    transition: all 0.5s;
    font-weight: 200;
}

input[type=date] {
    color-scheme: dark;
}
.connect-btn:hover {
    color: #0048e4;
    background-color: black;
    border-color: transparent;
}

.walled-ad {
    padding: 5px 10px;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 20px;
}

.walled-ad .user-icon i {
    color: #f5841f;
    margin-right: 5px;
}

.nav-link {
    font-weight: 300 !important;
    color: #ffffff !important;
    /* color: #059630!important; */
}

.hover-text{
    cursor: pointer;
}
.hover-text:hover {
opacity:75% ;
}
.hover-text:active {
    opacity:90% ;
    }
/* .navbar-nav .nav-link .active {
  color: #f5841f!important; 
} */
/* .nav-item .nav-link :active{
  color: red;
} */
.nav-link:hover {
    color: #f5841f !important;
}


.main-box {
    width: 100%;
    min-height: auto;

}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    /* transition: opacity 0.3s; */
    font-size: 18px;
    text-align: center;
}
.home-bg {
    background:url('./image/bg.png');

    /* background-position: fixed; */
    position: absolute;
    background-attachment: fixed;
    background-size: cover;
    overflow: auto;

    min-height: 100vh;
    width: 100%;
}

.poke-bg {
    background-image: url('./image/bg.jpg');
    background-position: fixed;
    background-size: cover;
    /* overflow: auto; */
    position: relative;
    width: 100%;
    min-height: 100vh;
}

.cus-span {
    color: #5cc6d0 !important;

}

.offcanvas-start-cus {
    top: 84px;
    left: 0;
    width: 260px;
    border-right: 1px solid rgba(0, 0, 0, .2);
    transform: translateX(-100%);
    background-color: #313131;
    color: #fff;
}

ul li {
    list-style: none;
}

.side-ul {
    padding-left: 0;
}

.side-ul li {
    /* background-color: #ffffff; */
    /* margin: 8px 0px; */
    padding: 8px 0px;

}

.side-ul li a {
    text-decoration: none !important;
    color: #ffffff;
    line-height: 30px;
    padding: 20px;


}

.dash-active {
    background-color: #093adb;
    border-radius: 5px;
    color: #ffffff;
}

.dash-active a {
    color: #ffffff !important;
}

.side-ul li i {
    margin: 0px 7px;
}

.bg-offcanwas-nav {
    background-color: #1f1f20 !important;
    border-radius: 10px;
}

.side-ul li:hover {
    color: #ffffff;
    background-color: #093adb;
    border-radius: 5px;
}

.side-ul li:hover a {
    color: #ffffff;
    /* background-color: #4211867c; */

}

.side-ul li:hover a {
    color: #ffffff;
    /* background-color: #4211867c; */

}


/* .register-box {
    position: relative;
    top: 50%;
    left: 50%;
    width: 400px;
    background-color: #fff;
    transform: translate(-50%, 50%);
    text-align: center;
    padding: 10px;
    box-shadow: 4px 8px 8px hsl(0deg 9.33% 97.72% / 38%);
    border-radius: 10px;
} */

.register-box {
    position: relative;
    top: 0;
    left: 50%;
    width: 450px;
    /* height: 450px; */
    background-color: #121111a6;
    transform: translate(-50%, 0%);
    text-align: center;
    padding: 25px 0px;
    box-shadow: 4px 8px 8px hsl(23.35deg 7.2% 20.24%);
    border-radius: 10px;
    border: 1px solid #ffffff6e
}

.rglogo-box h2 {
    background: black;
    margin-top: 5px;
    font-size: 22px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.register-btn {
    padding: 6px 30px;
    width: 60%;
    min-height: 45px;
    margin-top: 10px;
    color: white;
    /* border: #000000; */
    background: #093adb;
    border-radius: 8px;
    border: 0;
    font-weight: 600;
    transition: all 0.5s;
}

.register-btn:hover {
    background: black;
    color: #093adb;
    transition: all 0.5s;
}

.tab-content {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: #ffffff3b;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.register-img img {
    width: 300px;
}

/* .allotment-box{
  position:relative;
  top:50%;
  left:50%;
  width: 400px;
  transform: translate(50%,50%);
  background-color: #fff;
  padding: 10px;
  box-shadow: 4px 8px 8px hsl(0deg 9.33% 97.72% / 38%);
  border-radius: 10px;
} */
.allotment-box {
    position: relative;
    top: 50%;
    left: 50%;
    margin-top: 50px;
    transform: translate(-50%, -50%);
    /* background-color: #ffff; */
    width: 450px;
    padding: 15px;
    /* height: 430PX; */
    border-radius: 20px;
    background-color: #ffffff29;
    border: 1px solid #fff;
}

.cus-label {
    color: #fff;
}

.cus-control-form {
    background-color: black;
    color: #FFFF;
}

.cus-control-form:focus {
    background-color: black;
    color: #FFFF;
    box-shadow: none;
    border-color: #e7e7e7;
}

.cus-card-token-ad {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: #ffffff3b;
    color: #fff;
    border-radius: 10px;
    /* border: 1px solid #fff; */
    margin-bottom: 10px;
    min-height: 171px;
    padding: 10px;
}

.tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    white-space: nowrap;
    color: #fff;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;

    /* background-color: var(--bs-table-bg); */
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
    background: #313131;
    color: #ffff;
    border-radius: 20px;
    border: 0 !important;
    /* border-bottom: 0; */
}

.cus-table {

    border-radius: 20px;
}

.form-control:disabled {
    background-color: transparent;
    opacity: 1;
}

.header-lable {
    color: white;
}

/*loading css*/
.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.733);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8000000;
}

.loading p {
    font-size: 3em;
    animation: anim 1s infinite;
}

.empty-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    text-align: center;
}

@keyframes anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* //footer */

.footer {
    color: white;
    padding: 25px;
    background: black;
    background: -webkit-linear-gradient(to right,
            #434343,
            #000000);
    background: linear-gradient(to right, #434343, #000000);

}

.footer a {
    text-decoration: none;
    color: white;
    padding: 10px;
}

.footer a:hover {
    color: #ffefba;
    border-bottom: 1px solid #ffefba;
}

/* // page not found  */

.pnf {
    display: flex;
    min-height: 65vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pnf-title {
    font-size: 100px;
    font-weight: 700;
}

.pnf-heading {
    font-weight: normal;
}

.pnf-btn {
    color: #ffffff;
    border: 1px solid black;
    text-decoration: none;
    padding: 10px;
    margin-top: 10px;
}

.deod-logo {
    width: 18px;
    height: 18px;
    margin: 0px 5px;
}

.total-deodprice {
    font-size: 11px;
    text-align: center;
}

.total-deodprice p {
    margin-bottom: 0;
}

.total-deodprice p:nth-of-type(1) {
    color: #fff;
    font-weight: bold;
}

.total-deodprice p:nth-of-type(2) {
    color: green;
    font-weight: bold;
}


/* ===========register=============== */

/* .register-cont{
  display: flex;
  align-items: center;
  height: 70vh;
  justify-content: center;
  flex-direction: column;
} */

.product-link {
    text-decoration: none !important;
    color: black !important;

}

.card-img {
    width: 100%;
    height: 240px;
}

.card-style {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s;

}

.copybox {
    /* display: flex; */
    color: #fff;
    font-size: 15px;
}

.card-style:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.refbox {
    display: flex;
    /* vertical-align: baseline; */
    align-items: center;
}

.refbox p {
    margin-bottom: 0;
}

body {
    padding-right: 0px !important;
    overflow: auto !important;
}

.deod-balance {
    background-color: #ffff;
    width: 300px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    /* padding: 10px; */
    border-radius: 5px;
    box-shadow: 4px 8px 8px hsl(23.35deg 7.2% 20.24%);

}



.deod-walled-icon i {
    font-size: 40px;
    color: #ffffff;


}

.walled-deod-iconclr {
    background-color: #d58136;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.deod-balance p:nth-child(1) {
    color: #3a3a3a;
    margin-bottom: 2px;
    margin-top: 5px;
    font-weight: 600;
    text-transform: capitalize;

}

.deod-balance p:nth-child(2) {
    color: rgb(53, 122, 53);
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
}

.main {
    position: relative;
    display: flex;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    background:#3b436c;
    transition: 0.5s;
    z-index: 999;
    padding: 10px;
}

.addImage {
    cursor: pointer;
}

.addImage:hover {
    opacity: 85%;
}

.addImage:active {
    opacity: 70%;
}

.sidebar .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: #093adb;
    color: #ffffff !important;
}

.sidebar .nav-pills .nav-link {
    color: #093adb;
    font-weight: 500;
    padding: 15px 0;
}

.sidebar .toggle-btn {
    display: none;
}

.sidebar .logo img {
    width: 50px;
    margin-bottom: 15px;
}

.sidebar .logo {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: none;
}

.sidebar .logo span {
    font-size: 20px;
    color: #093adb;
    font-weight: 600;
}

.content {
    margin-left: 250px;
    min-height: 100vh;
    position: absolute;
    background:url('./image/bg.png');
    background-attachment: fixed;
    background-size: cover;
    overflow: auto;
    min-height: 100vh;
    width: 100%;
    transition: 0.5s all ease;
    width: calc(100% - 250px);
    padding: 20px;
}
.content1{
    margin-left: 250px;
    min-height: 100vh;
    position: absolute;
    background:#2d1a80;
    /* background:url('./image/bg.png'); */
    background-attachment: fixed;
    background-size: cover;
    overflow: auto;
    min-height: 100vh;
    width: 100%;
    transition: 0.5s all ease;
    width: calc(100% - 250px);
    padding: 20px;
}

.sidebar.show {
    margin-left: -250px;

}

.header-box {
    background-color: #ffffff3b;
    color: white;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    min-height: 55px;
}

.header-box .dropdown-toggle::after {
    display: none;
}

.toggle-btn i {
    font-size: 25px;
    color: black;
    /* margin-left: 20px; */
}

.header-box .dropdown-menu.show {
    display: block;
    background: #313131;
    border: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    top: 40px;
    right: 0px;
}

.header-box .show .dropdown-item {
    padding: 10px 14px;
    transition: 0.5s all ease;
}

.header-box .show .dropdown-item:hover {
    background: #093adb;
    color: #313131;
}

.content.show {
    width: 100% !important;
    margin-left: 0;
}
.content1.show {
    width: 100% !important;
    margin-left: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:focus-visible {

    outline: 1px solid black;
}

@media only screen and (max-width: 600px) {
    .countdown-item{
        width: auto;
    }
    .empty-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 20px;
        text-align: center;
    }

    .offcanvas-start-cus {
        top: 70px;
        left: 0;
        width: 66%;
        border-right: 1px solid rgba(0, 0, 0, .2);
        transform: translateX(-100%);
    }

    body {
        padding-right: 0px !important;
    }

    .register-box {
        width: 100%;
    }

    .allotment-box {
        width: 100%;
    }

    .cus-card-token-ad {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        background-color: #ffffff3b;
        color: #fff;
        border: 1px solid #fff;
        margin-bottom: 15px;
        min-height: 171px;
        padding: 10px;
    }

    .refbox {
        display: inline-block;
        /* vertical-align: baseline; */
        align-items: center;
    }

    .boobtn {
        display: block;
        margin: 10px 0px;
        /* vertical-align: baseline; */

    }

    .copybox {
        /* display: flex; */
        color: #fff;
        font-size: 14px;
    }

}

@media (max-width: 992px) {
    .content {
        margin-left: 0;
        width: 100%;
        padding: 20px;
    }
    .content1 {
        margin-left: 0;
        width: 100%;
        padding: 20px;
    }

    .sidebar {
        left: -300px;
    }

    .sidebar .toggle-btn {
        display: block;
        color: var(--light);
        display: inline-block;
        color: var(--light);
        background: var(--primary);
        padding: 5px 8px;
        position: absolute;
        right: 0;
        top: 0;
    }

    .sidebar.show {
        left: 0;
        margin-left: 0;
    }
}